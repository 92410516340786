/* You can add global styles to this file, and also import other style files */

@use 'projects/theme/src/lib/style/typography.scss';
@use 'projects/theme/src/lib/style/utils.scss';
@use 'projects/theme/src/lib/style/sizes.scss';
@use 'projects/theme/src/lib/style/private-theme' as theme;
@use 'projects/theme/src/lib/style/breakpoints.scss';
@use 'sass:map';

.mat-icon {
  //noinspection CssNoGenericFontName
  font-family: 'Material Icons Outlined' !important;
}

html,
body {
  margin: 0;
  padding: 0;
}

.item-wrapper {
  padding: sizes.$large 0;
  width: 80vw;
  margin: 0 auto;

  @media (max-width: 1199px) {
    width: 96vw;
    padding: sizes.$small 0;
  }
}

.items {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: sizes.$large;

  .item {
    grid-column: 1 / 3;
    padding: sizes.$medium-plus sizes.$medium;
    border-radius: 3px;
    border: 1px solid theme.$line-color;
    overflow-x: hidden;

    @media (min-width: map.get(breakpoints.$grid-breakpoints, 'md')) {
      grid-column: initial;
      padding: 24px 16px;
    }

    &.full-width {
      grid-column: 1 / 3;
    }

    mat-form-field,
    mat-slider {
      width: 100%;
    }

    mat-form-field,
    mat-chip {
      margin-bottom: sizes.$medium;
    }

    mat-radio-button,
    mat-checkbox {
      display: block;
    }

    mat-slide-toggle {
      margin: 0 sizes.$medium sizes.$medium 0;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .mdc-button,
      .mat-mdc-icon-button {
        margin: 0 sizes.$medium sizes.$medium 0;
      }
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 80%;
    }

    .card-content {
      img {
        display: block;
        width: 100%;
      }
    }
  }
}

.example-checkbox,
.example-radio {
  margin: sizes.$small 0;
}
